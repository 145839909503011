<template>
  <v-main>
    <div
      v-if="!valorImagen"
      class="d-flex flex-column justify-space-between align-center"
    >
      <v-img
        :src="imgLogo"
        max-width="200"
      />
    </div>
    <router-view />
    <!-- <dashboard-core-footer /> -->
  </v-main>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    /*  components: {
      DashboardCoreFooter: () => import('./Footer'),
    }, */
    data: () => ({
      valorImagen: false,
      url: '',
      imgLogo: '',
    }),
    mounted () {
      this.imgLogo = window.__env.dataCfg.imgLogo
      // this.url = localStorage.getItem('urlSitio') + '/assets/logo.jpeg'
      this.valorImagen = this.$store.state.valorEnviado
    },
    methods: {
      irInicio () {
        this.$router.replace('/')
      },
    },
  }
</script>
